import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, useLang, EntityValuePill, usePageEntity } from '@shapeable/ui';
import { Page } from '@shapeable/rhodian-types';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-product-industries');

// -------- Types -------->

export type SliceProductIndustriesProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const SliceProductIndustriesDefaultProps: Omit<SliceProductIndustriesProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    flex-basis: 50%;
  `,
  desktop: css`
    flex-basis: 100%;
  `,
});

const IndustryGroupsStyles = breakpoints({
  base: css`
    display: flex;
    list-style: none;
    margin: ${theme.UNIT(4)} 0 0 0;
    padding: 0;
    flex-wrap: wrap;
  `,
});

const IndustryGroupItemStyles = breakpoints({
  base: css`
    margin: 0;
    padding: 0;
    margin-right: ${theme.UNIT(3)};
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const IndustryGroupStyles = breakpoints({
  base: css`
    font-size: 0.85em;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
    
    IndustryGroups: styled.ul`${IndustryGroupsStyles}`,
      IndustryGroupItem: styled.li`${IndustryGroupItemStyles}`,
        IndustryGroup: styled(EntityValuePill)`${IndustryGroupStyles}`,

};

export const SliceProductIndustries: Shapeable.FC<SliceProductIndustriesProps> = (props) => {
  const { className, entity } = props;
  const { content } = entity;

  const page = usePageEntity() as Page;
  const product = page.product;

  const t = useLang();

  const industryGroups = product?.industryGroups || [];

  return (
    <My.Container className={cls.name(className)}>
    {
      content &&
      <My.Content entity={content} />
    }
    {
      !!industryGroups.length &&
      <My.IndustryGroups>
      {
        industryGroups.map((industryGroup, index) => {
          return (
            <My.IndustryGroupItem key={index}>
              <My.IndustryGroup variant='content' label={t('Covered')} entity={industryGroup} />
            </My.IndustryGroupItem>
          )
        })
      }
      </My.IndustryGroups>
    }
    </My.Container>
  )
};

SliceProductIndustries.defaultProps = SliceProductIndustriesDefaultProps;
SliceProductIndustries.cls = cls;