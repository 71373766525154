import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Slice } from '@shapeable/rhodian-types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { ProductCard } from './product-card';
const cls = classNames('product-grid');

// -------- Types -------->

export type ProductGridProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const ProductGridDefaultProps: Omit<ProductGridProps, 'entity'> = {
  
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const CardStyles = breakpoints({
  base: css`
    flex: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(ProductCard)`${CardStyles}`,
};

export const ProductGrid: Shapeable.FC<ProductGridProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { products = [] } = entity
  
  return (
    <My.Container className={cls.name(className)}>
      <Grid 
        desktopMinColumns={2}
        desktopAutoColumns={2}
        desktopLargeMinColumns={2}
        desktopLargeAutoColumns={2}
        tabletAutoColumns={2}
        landscapeAutoColumns={1}
        items={products.map(Card => 
          <My.Card key={Card.id} entity={Card} />
        )}/>
    {children}
    </My.Container>
  )
};

ProductGrid.defaultProps = ProductGridDefaultProps;
ProductGrid.cls = cls;

